<app-header></app-header>
<div class="checkout-container" [ngClass]="themeService.currentPalette">
    <div  *ngIf="isActive"   class="title-checkout"><h1>{{ 'CHECKOUT.TITLE' | translate }}</h1></div>
    <div  *ngIf="!isActive"   class="title-checkout"><h1>{{ 'CHECKOUT.MAINTENANCE' | translate }}</h1></div>
    <div class="checkout-detail">
    <div class="checkout-column left-column">
      <!-- Bloque de inicio de sesión y registro -->
      <!-- <div *ngIf="!auth.isLoggedIn()" class="login-register-block">
        <h2>¿Tiene cuenta?</h2>
        <div class="button-login">
        <button (click)="showLoginPopup()" class="btn-login">Iniciar Sesión</button>
        <button class="btn-register">Registrarse</button>
        </div>
      </div> -->
      
      <!-- Formulario de checkout -->
      <div *ngIf="isActive" class="checkout-form-block"><div class="checkout-dialog">
        <h2>{{ 'CHECKOUT.SHIPPING_PAYMENT_INFO' | translate }}</h2><button *ngIf="auth.isLoggedIn()"  (click)="togglemisdirecciones()"><img src="/assets/icons/plus.svg" alt="">{{ 'CHECKOUT.MY_ADDRESSES' | translate }}</button>
        </div><form [formGroup]="form" >
            <!-- Columna izquierda del formulario -->
            <div class="form-column">
              <div class="form-group">
                <label for="nombre">{{ 'CHECKOUT.FORM.FIRST_NAME' | translate }}:</label>
                <input [formControl]="form.controls['name']"  type="text" id="nombre" name="nombre" [placeholder]="'CHECKOUT.FORM.FIRST_NAME' | translate" required>
                <small
                        *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                        class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
              </div>
              <div class="form-group">
                <label for="apellido">{{ 'CHECKOUT.FORM.LAST_NAME' | translate }}:</label>
                <input  [formControl]="form.controls['lastname']" type="text" id="apellido" name="apellido" [placeholder]="'CHECKOUT.FORM.LAST_NAME' | translate" required>
                <small
                    *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched"
                    class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
              </div>
              <div class="form-group">
                <label for="direccion">{{ 'CHECKOUT.FORM.ADDRESS' | translate }}:</label>
                <input [formControl]="form.controls['address']" type="text" id="direccion" name="direccion" [placeholder]="'CHECKOUT.FORM.ADDRESS' | translate" required>
                <small
                    *ngIf="form.controls['address'].hasError('required') && form.controls['address'].touched"
                    class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
              </div>
           
              <div *ngIf="!auth.isLoggedIn()"  class="form-group">
                <label for="email">{{ 'CHECKOUT.FORM.EMAIL' | translate }}:</label>
                <input  [formControl]="form.controls['email']"  type="email" id="email" name="email" [placeholder]="'CHECKOUT.FORM.EMAIL' | translate " required>
                <small
                    *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                    class="form-error-msg">{{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
              </div>
              <div class="form-group">
                <label for="provincia">{{ 'CHECKOUT.FORM.PROVINCE' | translate }}:</label>
                <select  (change)="onProvinceChange()" [formControl]="form.controls['province']" id="provincia" name="provincia">
                  <option disabled="" selected="" value="">{{ 'CHECKOUT.FORM.SELECT_OPTION' | translate }}</option>
                  <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>

              </select>
              <small
              *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched"
              class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>

              </div>
              <div class="form-group">
                <label for="corregimiento">{{ 'CHECKOUT.FORM.DISTRICT' | translate }}</label>
                <select [formControl]="form.controls['corregimiento']" id="corregimiento" name="corregimiento">
                  <option disabled="" selected="" value="">{{ 'CHECKOUT.FORM.SELECT_OPTION' | translate }}</option>
                  <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>

                </select>

                <small
                *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched"
                class="form-error-msg">  {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>

              </div>
            </div>
<hr>
            <div class="checkout-dialog">
            <h2  class="pago">{{ 'CHECKOUT.PAYMENT_INFO' | translate }}</h2> <button *ngIf="auth.isLoggedIn()" (click)="togglemistarjetas()"><img src="/assets/icons/plus.svg" alt="">{{ 'CHECKOUT.MY_CARDS' | translate }}</button>
          </div>
            <div class="form-group">
                <label for="tarjeta">{{ 'CHECKOUT.FORM.CARD_NAME' | translate }}</label>
                <input [formControl]="form.controls['name_tdc']" type="text" id="tarjeta" name="cc-name" [placeholder]="'CHECKOUT.FORM.CARD_NAME' | translate" required>
                <small
                    *ngIf="form.controls['name_tdc'].hasError('required') && form.controls['name_tdc'].touched"
                    class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
              </div>
              <div class="form-group">
                <label for="numero-tarjeta">{{ 'CHECKOUT.FORM.CARD_NUMBER' | translate }}:</label>
                <input
                  (input)="onCreditCardInput(); formatCreditCardNumber()"
                  (keypress)="onlyNumberKey($event)"
                  [formControl]="form.controls['number_tdc']"
                  type="text"
                  id="numero-tarjeta"
                  name="cc-number"
                  [placeholder]="'CHECKOUT.FORM.CARD_NUMBER' | translate "
                  required
                />
                <small
                  *ngIf="form.controls['number_tdc'].hasError('required') && form.controls['number_tdc'].touched"
                  class="form-error-msg"
                >
                {{ 'CHECKOUT.FORM.REQUIRED' | translate }}
                </small>
                <small
                  *ngIf="form.controls['number_tdc'].hasError('invalidLength') && form.controls['number_tdc'].touched"
                  class="form-error-msg"
                >
                {{ 'CHECKOUT.FORM.INVALID_LENGTH' | translate }}
                </small>
                <small
                  *ngIf="form.controls['number_tdc'].hasError('invalidCardNumber') && form.controls['number_tdc'].touched"
                  class="form-error-msg"
                >
                {{ 'CHECKOUT.FORM.INVALID_CARD_NUMBER' | translate }}
                </small>
              </div>

              <div class="form-column">

                <div class="form-group">
                  <label for="city">{{ 'CHECKOUT.FORM.CITY' | translate }}:</label>
                  <input [formControl]="form.controls['city']"  type="text" id="city" name="city" [placeholder]="'CHECKOUT.FORM.CITY' | translate" required>
                  <small
                          *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                          class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
                </div>
                <div class="form-group">
                  <label for="street">{{ 'CHECKOUT.FORM.STREET' | translate }}:</label>
                  <input  [formControl]="form.controls['street']" type="text" id="street" name="street" [placeholder]="'CHECKOUT.FORM.STREET' | translate" required>
                  <small
                      *ngIf="form.controls['street'].hasError('required') && form.controls['street'].touched"
                      class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
                </div>

                <div class="form-group">
                  <label for="postalCode">{{ 'CHECKOUT.FORM.POSTAL_CODE' | translate }}:</label>
                  <input (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['postalCode']" type="text" id="postalCode" name="postalCode" [placeholder]="'CHECKOUT.FORM.POSTAL_CODE' | translate" required>
                      <small *ngIf="form.controls['postalCode'].hasError('required') && form.controls['postalCode'].touched" class="form-error-msg"> **{{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
                      <small *ngIf="form.controls['postalCode'].hasError('pattern') && form.controls['postalCode'].touched" class="form-error-msg"> **{{ 'CHECKOUT.FORM.INVALID_FORMAT' | translate }} </small>
                </div>

                <div class="form-group">
                  <label for="phone">{{ 'CHECKOUT.FORM.PHONE' | translate }}:</label>
                  <input (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['phone']" type="text" id="phone" name="phone" [placeholder]="'CHECKOUT.FORM.PHONE' | translate " required>
                  <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" class="form-error-msg"> **{{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
                  <small *ngIf="form.controls['phone'].hasError('pattern') && form.controls['phone'].touched" class="form-error-msg"> **{{ 'CHECKOUT.FORM.INVALID_FORMAT' | translate }} </small>
                </div>



              </div>
              
              
              <div class="datos-mac">
              <div class="form-group">
                <label for="mes-expiracion">{{ 'CHECKOUT.FORM.EXPIRATION_MONTH' | translate }}:</label>
                <select [formControl]="form.controls['month']"  id="mes-expiracion" name="mes-expiracion" required>
                  <option value="" disabled selected>{{ 'CHECKOUT.FORM.SELECT_MONTH' | translate }}</option>
                  <option value="01" id="mes-exp-0">{{ 'MONTHS.JANUARY' | translate }}</option>
                  <option value="02" id="mes-exp-1">{{ 'MONTHS.FEBRUARY' | translate }}</option>
                  <option value="03" id="mes-exp-2">{{ 'MONTHS.MARCH' | translate }}</option>
                  <option value="04" id="mes-exp-3">{{ 'MONTHS.APRIL' | translate }}</option>
                  <option value="05" id="mes-exp-4">{{ 'MONTHS.MAY' | translate }}</option>
                  <option value="06" id="mes-exp-5">{{ 'MONTHS.JUNE' | translate }}</option>
                  <option value="07" id="mes-exp-6">{{ 'MONTHS.JULY' | translate }}</option>
                  <option value="08" id="mes-exp-7">{{ 'MONTHS.AUGUST' | translate }}</option>
                  <option value="09" id="mes-exp-8">{{ 'MONTHS.SEPTEMBER' | translate }}</option>
                  <option value="10" id="mes-exp-9">{{ 'MONTHS.OCTOBER' | translate }}</option>
                  <option value="11" id="mes-exp-10">{{ 'MONTHS.NOVEMBER' | translate }}</option>
                  <option value="12" id="mes-exp-11">{{ 'MONTHS.DECEMBER' | translate }}</option>
                </select>
                <small
                *ngIf="form.controls['month'].hasError('required') && form.controls['month'].touched"
                class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>

              </div>
              
              <div class="form-group">
                <label for="ano-expiracion">{{ 'CHECKOUT.FORM.EXPIRATION_YEAR' | translate }}:</label>
                <select [formControl]="form.controls['year']" id="ano-expiracion" name="ano-expiracion" required>
                  <option value="" disabled selected>{{ 'CHECKOUT.FORM.SELECT_YEAR' | translate }}</option>
                  <option *ngFor="let year of expirationYears" [value]="year.value" [id]="'ano-exp-' + year.value">
                    {{ year.label }}
                  </option>
                </select>
                <small
                  *ngIf="form.controls['year'].hasError('required') && form.controls['year'].touched"
                  class="form-error-msg">
                  {{ 'CHECKOUT.FORM.REQUIRED' | translate }}
                </small>
              </div>
              <div class="form-group">
                <label for="cvv">{{ 'CHECKOUT.FORM.CVV' | translate }}:</label>
                <input maxlength="3"  (keypress)="onlyNumberKey($event)" (input)="onCVVInputChange()" [formControl]="form.controls['ccv']" type="password" id="cvv" name="cvv" placeholder="CVV" required>
                <small
                *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched"
                class="form-error-msg"> {{ 'CHECKOUT.FORM.REQUIRED' | translate }} </small>
              </div> 
            </div>
            <button (click)="checkout()" class="btn-submit">{{ 'CHECKOUT.FORM.SUBMIT' | translate }}</button>
          
          </form>
      </div>
    </div>
  
  
<div *ngIf="isActive" class="checkout-summary">
    <h2>{{ 'CHECKOUT.ORDER_SUMMARY' | translate }}</h2>
    <div class="product-item">
      <img src="{{plan?.url_image}}" alt="Producto 1">
      <div class="product-info">
        <h3>{{ plan?.name_plan }}</h3>
        <p>{{ 'CHECKOUT.DESCRIPTION' | translate }}: {{ plan?.description }} </p>
      </div>
    </div>
    <hr>
    <!-- Total -->
    <div class="order-total">
      <p>{{ 'CHECKOUT.TOTAL' | translate }}: ${{ plan?.feeCost_plan }}</p>
    </div>
  </div>
  
</div>
  </div>
  